<template>
  <div class="container">

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-4">

      <!-- section students -->
      <section v-if="chooseLanguageLevel_form.students.length">
        <h4 class="text-center mb-4">
          Студенты
        </h4>

        <DataTable :value="chooseLanguageLevel_form.students" :paginator="true" :rows="20"
                   showGridlines
                   paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                   :rowsPerPageOptions="[20,30,40,50,100,200]"
                   :globalFilterFields="['barcode', 'last_name','first_name']"
                   v-model:filters="filters"
                   currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" stripedRows
                   responsiveLayout="scroll">
          <template #header>
            <div class="p-d-flex p-jc-between p-ai-center">
              <span class="p-input-icon-left">
                <i class="pi pi-search"/>
                <InputText v-model="filters['global'].value" placeholder="Поиск студента"/>
              </span>
            </div>
          </template>
          <Column header="№" :style="{width: '60px'}">
            <template #body="{index}">
              {{index+1}}
            </template>
          </Column>
          <Column header="Баркод" field="barcode"></Column>
          <Column header="ФИО">
            <template #body="{data}">
              {{data?.last_name}} {{data?.first_name}} {{data?.middle_name}}
            </template>
          </Column>
          <Column header="Курс" field="study_course"></Column>
          <Column header="ОП">
            <template #body="{data}">
              {{data?.education_speciality_code}} {{data?.education_speciality_name}}
            </template>
          </Column>
          <Column header="Уровень языка">
            <template #body="{data}">
              <div v-for="(langLevel, langLevelIndex) in data.language_level"
                   :key="langLevelIndex">


                <div v-if="langLevel.is_english == 1">

                  <p><strong>Foreign language 1,2</strong></p>

                  <select class="form-control form-select"
                          @input="changeLanguageLevel(data.student_data_id, 'eng', $event)">

                    <option v-for="(item, index) in getLanguagesLevel(langLevel.is_english)" :value="item.id"
                            :key="index"
                            :selected="data.language_level.find(i=>i.is_english == 1).language_level_id == item.id">{{ item.full_name }}
<!--                      :selected="langLevel.find(i=>i.is_english == 1).language_level_id == item.id"-->
                    </option>
                  </select>

                  <Button :class="data.student_data_id + langLevel.language_level_id +' mt-2'" icon="pi pi-save"
                          :loading="btnSaveDisabled[data.student_data_id]"
                          label="Сохранить" @click="saveLanguageLevel(data.student_data_id)" />
                  <hr>
                </div>


                <div class="mt-2" v-if="langLevel.is_english == null">
                  <p><strong>Казахский(русский) 1,2</strong></p>
                  <select class="form-control form-select"
                          @input="changeLanguageLevel(data.student_data_id, 'kaz', $event)">

                    <option v-for="(item, index) in [{id: 0, full_name:'Нет уровня'},...getLanguagesLevel(langLevel.is_english)]" :value="item.id"
                            :key="index"
                            :selected="data.language_level.find(i=>i.is_english === null).language_level_id == item.id">{{ item.full_name }}
<!--                      :selected="langLevel.find(i=>i.is_english == null).language_level_id == item.id"-->
                    </option>
                  </select>

                  <Button :class="data.student_data_id + langLevel.language_level_id + ' mt-2'" icon="pi pi-save"
                          :loading="btnSaveDisabled[data.student_data_id]"
                          label="Сохранить" @click="saveLanguageLevel(data.student_data_id)" />

                  <hr>
                </div>


              </div>
              <Button v-if="data.language_level.length < 2" class="mt-2 me-3 p-button-warning" label="Добавить" icon="pi pi-plus" @click="openAddAdmissionTestResultDialog(data.student_data_id)" />





            </template>
          </Column>
<!--          <Column header="Действие">-->
<!--            <template #body="{data}">-->

<!--              <Button :class="data.student_data_id" icon="pi pi-save"-->
<!--                      :loading="btnSaveDisabled[data.student_data_id]"-->
<!--                      label="Сохранить" @click="saveLanguageLevel(data.student_data_id)" />-->

<!--            </template>-->
<!--          </Column>-->
        </DataTable>


        <Dialog header="Добавление уровня языка" :visible="addAdmissionTestResultDisplay" style="width: 100%; max-width: 900px" :closable="false">


          <div class="col-md-12" >

            <div class="row" v-if="chooseLanguageLevel_form.students.find(i=>i.student_data_id  == add_admission_test_result_student_id).
            language_level.filter(i=>i.is_english == 1).length == 0">


              <div class="col-md-6">
                <p><strong>Foreign language 1,2</strong></p>

                <select class="form-control form-select"
                        @input="changeAddLanguageLevel('eng', $event)">

                  <option v-for="(item, index) in getLanguagesLevel(1)" :value="item.id"
                          :key="index">{{ item.full_name }}
                  </option>
                </select>

                <Button :class="add_admission_test_result_student_id + ' mt-2'" icon="pi pi-save"
                        :loading="btnSaveDisabled[add_admission_test_result_student_id]"
                        label="Сохранить" @click="saveLanguageLevel(add_admission_test_result_student_id)" />
              </div>


            </div>


            <div class="row mt-2" v-if="chooseLanguageLevel_form.students.find(i=>i.student_data_id  == add_admission_test_result_student_id).
            language_level.filter(i=>i.is_english == null).length == 0">
<!--              || chooseLanguageLevel_form.students.find(i=>i.student_data_id  == this.add_admission_test_result_student_id).language_level.length == 0"-->
              <div class="col-md-6">
                <p><strong>Казахский(русский) 1,2</strong></p>
                <select class="form-control form-select"
                        @input="changeAddLanguageLevel('kaz', $event)">
                  <option v-for="(item, index) in [{id: 0, full_name:'Нет уровня'},...getLanguagesLevel(null)]" :value="item.id"
                          :key="index">{{ item.full_name }}
                  </option>
                </select>
                <Button :class="add_admission_test_result_student_id + ' mt-2'" icon="pi pi-save"
                        :loading="btnSaveDisabled[add_admission_test_result_student_id]"
                        label="Сохранить" @click="saveLanguageLevel(add_admission_test_result_student_id)" />
              </div>

            </div>
          </div>
          <template #footer>
            <Button label="Закрыть" icon="pi pi-times" @click="closeAddAdmissionTestResultDialog" class="p-button-text"/>
          </template>
        </Dialog>

      </section>
      <!-- end section students -->





    </div>
  </div>
</template>


<script>

import {mapActions, mapGetters, mapState} from "vuex"
import {FilterMatchMode, FilterOperator} from "primevue/api";


export default {
  name: "ChooseStudentsLanguageLevel",
  data() {
    return {
      loading: true,
      btnSaveDisabled: {},
      studentsLangLevel: {},
      updateStudentsLangLevel: {},
      addStudentsLangLevel: {},
      addAdmissionTestResultDisplay: false,
      add_admission_test_result_student_id: null,

      filters: {
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'barcode': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'last_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
        'first_name': {
          operator: FilterOperator.AND,
          constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}]
        },
      },
    }
  },
  computed: {
    ...mapState('chooseLanguageLevel', ['chooseLanguageLevel_form']),
    //...mapGetters('languageskills', ['ALL_LANGUAGES_LEVEL']),
    ...mapState('languageskills', ['languageskills_form', 'languagesLevel']),
  },

  methods: {
    ...mapActions('chooseLanguageLevel', ['GET_ADMISSION_TEST_STUDENTS', 'POST_ADMISSION_TEST_RESULT', 'POST_UPDATE_ADMISSION_TEST_RESULT']),
    ...mapActions('languageskills', ['GET_LANGUAGES_LEVEL']),

    openAddAdmissionTestResultDialog(student_id) {
      this.add_admission_test_result_student_id = student_id
      this.addAdmissionTestResultDisplay = true
      console.log(this.add_admission_test_result_student_id, 'this.add_admission_test_result_student_id')
    },
    closeAddAdmissionTestResultDialog() {
      this.addAdmissionTestResultDisplay = false
    },

    getLanguagesLevel(is_english) {
      if (is_english == 1) {
        return [{id: 0, full_name:'Нет уровня'}, ...this.languagesLevel.filter(i=>i.id < 6)]
      }
      else if((is_english == null)) {
        return this.languagesLevel.filter(i=>i.id > 5)
      }
    },

    changeAddLanguageLevel(property, e) {
      let lang_level_id = e.target.value

      console.log('changeAddLanguageLevel lang_level_id', lang_level_id)



        // if (this.studentsLangLevel.find(i=>i.student_id == student_id)) {
        //   this.studentsLangLevel.find(i=>i.student_id == student_id).lang_level = lang_level_id
        // }
        // else {
        //   this.studentsLangLevel.push({
        //     lang_level: lang_level_id,
        //     student_id: student_id
        //   })
        // }
        this.addStudentsLangLevel['lang_level'] = lang_level_id
        this.addStudentsLangLevel['student_id'] = this.add_admission_test_result_student_id




      console.log(this.addStudentsLangLevel, 'addStudentsLangLevel')

    },

    changeLanguageLevel(student_id, property, e) {
      console.log(e.target, 'e target')
      let lang_level_id = e.target.value

      console.log('changeLanguageLevel lang_level_id', lang_level_id)
      let is_english = null
      if (property == 'eng') {
        is_english = 1
      }

      if (this.chooseLanguageLevel_form.students.find(i=>i.student_data_id == student_id).language_level.find(i=>i.is_english == is_english).language_level_id == null) {

        // if (this.studentsLangLevel.find(i=>i.student_id == student_id)) {
        //   this.studentsLangLevel.find(i=>i.student_id == student_id).lang_level = lang_level_id
        // }
        // else {
        //   this.studentsLangLevel.push({
        //     lang_level: lang_level_id,
        //     student_id: student_id
        //   })
        // }
        this.studentsLangLevel['lang_level'] = lang_level_id
        this.studentsLangLevel['student_id'] = student_id

      }
      else {

        this.updateStudentsLangLevel['lang_level'] = lang_level_id
        this.updateStudentsLangLevel['student_id'] = student_id
        

      }

      console.log(this.studentsLangLevel, 'studentsLangLevel')
      console.log(this.updateStudentsLangLevel, 'updateStudentsLangLevel')

    },

    async saveLanguageLevel(student_id) {

      this.addAdmissionTestResultDisplay = false
      this.btnSaveDisabled[student_id] = true

      if (this.studentsLangLevel['lang_level'] == 0 || this.updateStudentsLangLevel['lang_level'] == 0) {
        this.$error({text: 'Выберите уровень'})
      }
      else {
        if (Object.keys(this.studentsLangLevel).length > 0) {
          // let form = {
          //   lang_level: 1,
          //   student_id: 1645,
          // }
          let postData = await this.POST_ADMISSION_TEST_RESULT(this.studentsLangLevel)
          if (postData) {
            this.studentsLangLevel = {}
            await this.GET_ADMISSION_TEST_STUDENTS()
            this.$message({text: 'Уровень языка сохранен'})
          }
        }

        if (Object.keys(this.updateStudentsLangLevel).length > 0) {
          let putData = await this.POST_UPDATE_ADMISSION_TEST_RESULT(this.updateStudentsLangLevel)
          if (putData) {
            this.updateStudentsLangLevel = {}
            await this.GET_ADMISSION_TEST_STUDENTS()
            this.$message({text: 'Уровень языка обновлен'})
          }
        }

        if (Object.keys(this.addStudentsLangLevel).length > 0) {
          let postData = await this.POST_ADMISSION_TEST_RESULT(this.addStudentsLangLevel)
          if (postData) {
            this.addStudentsLangLevel = {}
            this.add_admission_test_result_student_id = null
            await this.GET_ADMISSION_TEST_STUDENTS()
            this.$message({text: 'Уровень языка сохранен'})
          }
        }
      }



      this.btnSaveDisabled[student_id] = false
    }
  },
  async mounted() {
    await this.GET_ADMISSION_TEST_STUDENTS()
    await this.GET_LANGUAGES_LEVEL();
    this.loading = false
  },
}
</script>

<style scoped>

</style>